import { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import styles from './Router.module.css';

//Components

//Screens
import { Home } from 'screens/Home';

export const Router = memo(() => {
  return (
    <>
      <Helmet titleTemplate="%s - Emilys Studio" defaultTitle="Emilys Studio" />

      <div>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    </>
  );
});
